import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Avatar({ user, hiddenTitle, shortTitle }) {
  const initials = user.first_name.charAt(0) + user.last_name.charAt(0);
  const truncatedFirstName = user.first_name.length > 1 ? `${user.first_name.slice(0, 1)}.` : user.first_name;
  const truncatedLastName = user.last_name.length > 15 ? `${user.last_name.slice(0, 15)}..` : user.last_name;

  return (
    <div className="flex flex-col items-center">

      {/* Avatar Circle */}
      <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full border border-gray-200">
        <span className="font-medium text-gray-600">
          {user.invitation_token ? (
            <FontAwesomeIcon icon={faEnvelope} />
          ) : (
            initials
          )}
        </span>
      </div>

      {/* Name Display */}
      {user.invitation_token ? (
        <div className="text-sm md:text-md text-danger">Invitation pending</div>
      ) : !hiddenTitle ? (
        shortTitle ? (
          <div className="text-sm md:text-md flex gap-1 mt-1">
            <span>{truncatedFirstName}</span>
            <span>{truncatedLastName}</span>
          </div>
        ) : (
          <div className="text-sm md:text-md flex gap-1 mt-1">
            <span>
              {user.first_name.slice(0, 1)}
              {user.first_name.length > 1 && "."}
            </span>
            <span>
              {user.last_name.slice(0, 15)}
              {user.last_name.length > 15 && ".."}
            </span>
          </div>
        )
      ) : null}
    </div>
  );
}
