import axios from "axios";

const useRemoveAffiliateExpensesToken = () => {
    const removeAffiliateExpensesToken = async (user_token) => {
        const url = "https://www.app.figame.com/api.json";

        const params = {
            device: "figamecarsapp",
            deviceid: "HCMsUu6Ravc3pwNX",
            action: "removeAffiliateExpensesToken",
            user_token: user_token,
        };

        try {
            await axios.post(url, {}, { params: params });
        } catch (error) {
        }
    };

    return { removeAffiliateExpensesToken };
};

export default useRemoveAffiliateExpensesToken;
