import StatePill from "./StatePill";
import { useState, useEffect } from "react";
import states from "../../States"; // to be changed with dynamic array from DB when ready
import { faFile, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "../layout/InputField";

const ReportsFilters = ({ onChange }) => {
  // Variables
  const [filterState, setFilterState] = useState({
    selectedStates: [
      "unreported",
      "open",
      "processing",
      "approved",
      "reimbursed",
      "closed",
    ],
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    onChange(filterState);
  }, [filterState]);

  // Handle filter state checkboxes change
  const handleFilterCheckboxChange = (state) => {
    setFilterState((prevState) => {
      if (prevState.selectedStates.includes(state)) {
        return {
          ...prevState,
          selectedStates: prevState.selectedStates.filter((s) => s !== state),
        };
      } else {
        return {
          ...prevState,
          selectedStates: [...prevState.selectedStates, state],
        };
      }
    });
  };

  // Handle filter start date change
  const handleStartDateChange = (e) => {
    setFilterState((prevState) => ({
      ...prevState,
      startDate: e.target.value,
    }));
  };

  // Handle filter end date change
  const handleEndDateChange = (e) => {
    setFilterState((prevState) => ({ ...prevState, endDate: e.target.value }));
  };

  const ReportsFiltersWrapper = ({ children }) => {
    return (
      <>
        <div className="flex flex-col px-2 mx-2 mt-2">
          <div className="flex flex-row items-center gap-2">{children}</div>
        </div>
      </>
    );
  };

  return (
    <div>
      <ReportsFiltersWrapper>
        <FontAwesomeIcon
          icon={faFile}
          className="text-primary-700 pr-2 pb-2 text-xl"
          title="Select the categories of the expenses you want to display"
        />
        <div className="flex overflow-x-auto px-2">
          {states.map(
            (state) =>
              state !== "unreported" && (
                <div
                  onClick={() => handleFilterCheckboxChange(state)}
                  key={state}
                  className="flex items-center gap-2 mb-2 mr-4 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    checked={filterState.selectedStates.includes(state)}
                    onChange={() => { }}
                  ></input>
                  <StatePill state={state} />
                </div>
              )
          )}
        </div>
      </ReportsFiltersWrapper>
      <ReportsFiltersWrapper>
        <FontAwesomeIcon
          icon={faCalendar}
          className="text-primary-700 pt-1 text-xl"
          title="Select dates to display the expenses in this range"
        />

        <div className="flex flex-row gap-2 overflow-x-auto">
          <InputField
            type="date"
            value={filterState.startDate ?? ""}
            onChange={handleStartDateChange}
          />
          <InputField
            type="date"
            value={filterState.endDate ?? ""}
            onChange={handleEndDateChange}
          />
        </div>
      </ReportsFiltersWrapper>
    </div>
  );
};

export default ReportsFilters;
