import { createContext, useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import useRemoveAffiliateExpensesToken from "../actions/hooks/useRemoveAffiliateExpensesToken";

export const UserContext = createContext({});
export function UserContextProvider({ children }) {
	const [user, setUser] = useState(null);
	const [ready, setReady] = useState(false);
	const { removeAffiliateExpensesToken } = useRemoveAffiliateExpensesToken();

	const login = (data) => {
		setUser(data.user);
		setReady(true);
		localStorage.setItem("token", data.token);
		axios.defaults.headers.common["Authorization"] = "Bearer " + data.token;
	};

	const logout = () => {
		removeAffiliateExpensesToken(localStorage.getItem("token"));
		localStorage.removeItem("token");
		setUser(null);
		if (window.ReactNativeWebView)
			window.ReactNativeWebView.postMessage('logout');
	};

	useEffect(() => {
		let isMounted = true;

		const fetchUser = async () => {
			try {
				const response = await axios.get("user");

				if (isMounted) {
					setUser(response.data.user);
					setReady(true);
				}
			} catch (error) {
				// Handle errors more explicitly
			}
		};

		fetchUser();

		// Cleanup function to set isMounted to false when the component unmounts
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<UserContext.Provider value={{ user, setUser, ready, login, logout }}>
			{children}
		</UserContext.Provider>
	);
}
