import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const DropDown = ({ children, label, width, height }) => {
  const [showDropDownOptions, setShowDropDownOptions] = useState(false);
  const widthClass = width ? `${width}` : "w-auto";
  const heightClass = height ? `${height}` : "";

  return (
    <div className="relative inline-block">
      <div>
        <div
          className="bg-primary text-white font-semibold cursor-pointer hover:bg-secondary py-2 px-4 flex flex-col items-center rounded-md"
          onClick={() => setShowDropDownOptions((prev) => !prev)}
        >
          <div className={`flex flex-row gap-2 items-center ${widthClass} ${heightClass}`}>
            <span className="ml-auto mr-auto">{label}</span>
            {showDropDownOptions ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </div>
        </div>
      </div>

      {showDropDownOptions && (
        <div
          className={`z-50 absolute bg-white rounded-lg shadow border border-gray-50 ${widthClass} mt-1`}
          style={{ left: 0 }}
        >
          <ul className="text-sm">
            {React.Children.map(children, (child, index) => (
              <li key={index}>{child}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDown;
