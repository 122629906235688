import { useParams } from "react-router-dom";
import Container from "../components/layout/Container";
import useFetchArticleByTitle from "../actions/hooks/useFetchArticleByTitle";
import PageTitle from "./PageTitle";

const ArticlePage = () => {
    const { title } = useParams();
    const { article } = useFetchArticleByTitle(title);

    return (
        <>
            <div>
                <PageTitle title={article ? article.title.replace(/_/g, ' ') : ''} />
            </div>
            <Container>
                {article && (
                    <div className="bg-white rounded-lg p-4 text-gray-700">
                        <div dangerouslySetInnerHTML={{ __html: article.body }} />
                    </div>
                )}
            </Container>
        </>
    );
};

export default ArticlePage;
