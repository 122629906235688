import Price from "./Price";
import StatePill from "./StatePill";
import Receipt from "./Receipt";
import { useEffect, useState } from "react";
import useExpenseUpdate from "../../actions/hooks/useExpenseUpdate";
import Avatar from "../../components/user/Avatar";
import FormattedDate from "../elements/FormattedDate";

const ExpenseItem = ({ expense, onCheckChange, onClick, noDescription }) => {
	const [isFocused, setIsFocused] = useState(false);
	const [editableDescription, setEditableDescription] = useState(
		expense.description
	);

	useEffect(() => {
		setEditableDescription(expense.description);
	}, [expense.description]);

	const updatedExpense = { ...expense, description: editableDescription };

	const handleDescriptionChange = (e) => {
		setEditableDescription(e.target.value);
	};

	const { expenseUpdate, expenseUpdateResponse } = useExpenseUpdate();
	const submitDescriptionChange = () => {
		expenseUpdate(expense.id, updatedExpense);
	};

	useEffect(() => {
		onCheckChange();
	}, [expenseUpdateResponse]);

	return (
		< div
			onClick={onClick}
			className="flex flex-row items-center justify-between"
		>
			{/* Information */}
			<div className="flex flex-row gap-4 py-2">
				<div className="flex flex-col gap-2 lg:gap-0 items-center self-center">
					<div className="max-w-40 min-w-40 min-h-10 lg:min-h-14">
						<FormattedDate date={expense.date} showIcon={true} />
					</div>
					<div className="flex flex-col gap-1 items-center lg:hidden">
						<StatePill state={expense.state} size="sm" />
						<span className="text-sm line-clamp-2 min-w-50">
							{expense.merchant ? (
								<span className="text-xs font-semibold">
									{expense.merchant.name && expense.merchant.name.length > 20
										? `${expense.merchant.name.slice(0, 20)}...`
										: expense.merchant.name}
								</span>
							) : (
								<span className="text-xs font-semibold">Unknown merchant</span>
							)}
						</span>
						<span className="font-semibold text-sm">
							<Price price={expense.price} />
						</span>
					</div>
				</div>
				<div className="flex flex-col gap-1 items-center hidden lg:flex">
					<StatePill state={expense.state} size="sm" />
					<span className="text-sm line-clamp-2 max-w-20">
						{expense.merchant ? (
							<span className="text-xs font-semibold">
								{expense.merchant.name && expense.merchant.name.length > 20
									? `${expense.merchant.name.slice(0, 20)}...`
									: expense.merchant.name}
							</span>
						) : (
							<span className="text-xs font-semibold">Unknown merchant</span>
						)}
					</span>
					<span className="font-semibold text-sm">
						<Price price={expense.price} />
					</span>
				</div>
			</div>
			{/* Description */}
			<div className={`flex flex-col md:pl-4 ${noDescription === 'true' ? 'hidden' : 'hidden lg:inline-block'} `}>
				<span className="text-sm">Description</span>
				<div
					className={`flex w-full max-w-full md:max-w-52 md:min-w-52 max-h-16 min-h-16 overflow-hidden border rounded-lg ${isFocused ? "border-secondary" : "border-gray-300"
						}`}
					onClick={(e) => {
						e.stopPropagation();
						setIsFocused(true);
					}}
				>
					<textarea
						className="text-sm text-gray-600 w-full border-none outline-none px-2 resize-none max-h-16 overflow-hidden"
						value={editableDescription || ""}
						onChange={handleDescriptionChange}
						onBlur={() => {
							submitDescriptionChange();
							setIsFocused(false);
						}}
						maxLength={255}
						rows={4}
					/>
				</div>
			</div>
			{/* Receipt for device < 768px */}
			<div className="flex items-center lg:hidden max-w-24">
				{expense && expense.supporting_documents[0] ? (
					<Receipt src={expense.supporting_documents[0].file_path} />
				) : (
					<Receipt />
				)}
			</div>
			{/* Receipt for device >= 768px */}
			<div className="max-w-16 hidden lg:flex">
				{expense && expense.supporting_documents[0] ? (
					<Receipt src={expense.supporting_documents[0].file_path} />
				) : (
					<Receipt />
				)}
			</div>
		</div >
	);
};

export default ExpenseItem;
