import { Link } from "react-router-dom";
import AsideItem from "./AsideItem";
import LogoWhite from "../elements/LogoWhite";
import {
  faFile,
  faGear,
  faHouse,
  faReceipt,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import Avatar from "../user/Avatar";

export default function Aside() {
  const { ready, user } = useContext(UserContext);
  return (
    <aside
      id="default-sidebar"
      className="fixed bg-primary top-0 left-0 z-10 w-64 h-screen transition-transform -translate-x-full translate-x-0"
      aria-label="Sidebar"
    >
      <div className="flex flex-col h-screen">
        <div className="flex-grow h-full py-16 md:py-6 overflow-y-auto">
          <Link
            to="/account"
            className="flex items-center justify-center ps-2.5 mb-10"
          >
            <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
              {user && ready && <Avatar user={user} />}
            </span>
          </Link>
          <ul className="space-y-2 font-medium">
            <li>
              <AsideItem name="Home" icon={faHouse} to="/" />
            </li>
            <li>
              <AsideItem name="Expenses" icon={faReceipt} to="/expenses" />
            </li>
            <li>
              <AsideItem name="Reports" icon={faFile} to="/reports" />
            </li>
            <li>
              <AsideItem name="Settings" icon={faGear} to="/settings" />
            </li>
            <li>
              <AsideItem name="Help" icon={faCircleInfo} to="/help" />
            </li>
          </ul>
        </div>
        <LogoWhite px="10" py="10" />
      </div>
    </aside>
  );
}
