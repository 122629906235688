import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { triggerAside } from "../actions/asideAction";
import { useDispatch } from "react-redux";
import Container from "../components/layout/Container";

const PageTitle = ({ title = null }) => {
	const dispatch = useDispatch();
	const handleOnClick = () => {
		dispatch(triggerAside());
	};
	return (
		<Container>
			<div className="pb-8 md:pb-0">
				<div className="fixed top-0 z-35 left-0 backdrop-blur-sm w-full px-8 md:relative md:top-auto md:backdrop-blur-none md:w-auto md:px-0 flex items-center gap-4 pt-10 md:pt-0">
					<span
						className="inline-block md:hidden lg:hidden"
						onClick={handleOnClick}
					>
						<FontAwesomeIcon icon={faBars} />
					</span>
					<h1 className="text-2xl font-semibold">{title}</h1>
					<div className="border-b border-gray-300"></div>
				</div>
			</div>
		</Container>
	);
};

export default PageTitle;
