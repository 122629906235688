import { useParams } from "react-router-dom";
import Card from "../components/elements/Card";
import Container from "../components/layout/Container";
import useFetchReport from "../actions/hooks/useFetchReport";
import { useEffect, useState } from "react";
import LoadError from "../components/elements/LoadError";
import ReportName from "../components/reports/ReportName";
import PageTitle from "./PageTitle";
import Price from "../components/expenses/Price";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFile, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import FormattedDate from "../components/elements/FormattedDate";
import Button from "../components/elements/Button";
import Modal from "../components/elements/Modal";
import useExpenseEditModal from "../actions/hooks/utility/useExpenseEditModal";
import ExpenseForm from "../components/expenses/ExpenseForm";
import useReportExportCSV from "../actions/hooks/useReportExportCSV";
import DropDown from "../components/elements/DropDown";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import useReportUpdate from "../actions/hooks/useReportUpdate";
import { useDispatch } from "react-redux";
import { setRerender } from "../actions/rerenderAction";
import StatePill from "../components/expenses/StatePill";
import CommentSection from "../components/reports/CommentSection";
import { useSelector } from "react-redux";
import useReportAddExpense from "../actions/hooks/useReportAddExpense";
import { closeModal, openModal } from "../actions/modalActions";
import ReportAddExpenseList from "../components/reports/ReportAddExpenseList";
import Alert from "../components/elements/Alert";
import UsersDropdown from "../components/user/UsersDropdown";
import { useNavigate } from "react-router-dom";
import useReportRemoveExpense from "../actions/hooks/useReportRemoveExpense";
import ReportExpenseByCategoryList from "../components/reports/ReportExpenseByCategoryList";

export default function ReportPage() {
	const { handleOpenExpenseModal, modalSubmit, modalDelete, modal } = useExpenseEditModal();
	const { reportExportCSV } = useReportExportCSV();
	const { user } = useContext(UserContext);
	const { reportUpdate, reportUpdateResponse } = useReportUpdate();
	const { reportAddExpense, reportAddExpenseResponse } = useReportAddExpense();

	const dispatch = useDispatch();
	// Fetch report and add it to component state
	const { id } = useParams();
	const reportResponse = useFetchReport(id);
	const [report, setReport] = useState({});
	const [reportNewExpenses, setReportNewExpenses] = useState([]);
	const exp = useSelector((store) => store.expense);
	const isAdmin = user.user_roles.includes("administrator");
	const [toUser, setToUser] = useState(null);
	const navigate = useNavigate();
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [errorAlertMessage, setErrorAlertMessage] = useState(null);

	const { reportRemoveExpense } = useReportRemoveExpense(report.id);
	const handleRemoveExpense = (id) => {
		dispatch(setRerender());
		reportRemoveExpense({
			expense_id: id,
		});
		dispatch(setRerender());
	};

	useEffect(() => {
		if (reportResponse && reportResponse.data && reportResponse.data.report) {
			setReport(reportResponse.data.report);
		} else if (
			reportResponse &&
			(reportResponse === 401 || reportResponse === 500)
		) {
			navigate("/reports");
		}
	}, [reportResponse]);

	useEffect(() => {
		if (reportAddExpenseResponse) {
			if (reportAddExpenseResponse.status === 200) {
				dispatch(setRerender());
			} else {
				setErrorAlertMessage("Failed to add expense.");
			}
		}
	}, [reportAddExpenseResponse]);

	useEffect(() => {
		if (reportUpdateResponse) {
			if (reportUpdateResponse.status === 200) {
				dispatch(setRerender());
			} else {
				setErrorAlertMessage("Failed to update report.");
			}
		}
	}, [reportUpdateResponse]);

	useEffect(() => {
		if (toUser !== null && report) {
			reportUpdate(report.id, { ...report, to_id: toUser });
			dispatch(setRerender());
		}
	}, [toUser]);

	useEffect(() => {
		if (errorAlertMessage !== null) {
			setShowErrorAlert(true);
		}
	}, [errorAlertMessage]);

	if (!report.name) {
		return <LoadError />;
	}

	const totalCost = report.total.amount;
	const price = {
		amount: totalCost,
		currency: "EUR",
	};

	let categoryArray = [];
	Object.entries(report.expenses).map((category, index) => {
		let categoryObject = {
			headline: category[0],
			expenses: [],
			total: report.totals[category[0]],
		};
		category[1].map((currentExpense, index) => {
			categoryObject.expenses.push(currentExpense);
		});
		categoryArray.push(categoryObject);
	});

	const handleExportToCsv = () => {
		reportExportCSV(report.id);
	};

	const handleSubmitReport = () => {
		if (!isAdmin && report.to_id === user.id) {
			reportUpdate(report.id, { ...report, status: "closed" });
		} else {
			reportUpdate(report.id, { ...report, status: "processing" });
		}

		dispatch(setRerender());
	};

	const handleReimburseReport = () => {
		if (report.total_reimbursable.amount > 0) {
			reportUpdate(report.id, { ...report, status: "reimbursed" });
		} else {
			reportUpdate(report.id, { ...report, status: "approved" });
		}

		dispatch(setRerender());
	};

	const handleReopenReport = () => {
		reportUpdate(report.id, { ...report, status: "open" });
		dispatch(setRerender());
	};

	const handleCloseReport = () => {
		reportUpdate(report.id, { ...report, status: "closed" });
		dispatch(setRerender());
	};

	const handleOpenAddExpense = () => {
		setReportNewExpenses([]);
		dispatch(
			openModal({
				modalType: "inviteUser",
				modalTitle: `Add expenses to report:`,
				modalBodyData: {},
				key: "addExpense",
			})
		);
	};

	const handleAddExpenseSubmit = () => {
		const updatedReport = { ...report, expense_id: reportNewExpenses };
		reportAddExpense(report.id, updatedReport);
		dispatch(closeModal());
	};

	return (
		<>
			<div>
				<PageTitle title={`Reports / ${report.name}`} />
			</div>
			<Container>
				<Card
					header={
						report.creator.id === user.id &&
							report.status === "open" &&
							Object.keys(report.expenses).length > 0 ? (
							<Button label="Submit" onClick={handleSubmitReport}>
								<FontAwesomeIcon icon={faEnvelope} className="text-sm" />
							</Button>
						) : (report.assignee.id === user.id &&
							isAdmin &&
							(report.status === "processing" ||
								report.status === "closed")) ||
							(report.assignee.id === user.id &&
								!isAdmin &&
								report.status === "closed") ? (
							<Button
								label="Reopen report"
								onClick={handleReopenReport}
							></Button>
						) : (
							<StatePill state={report.status} />
						)
					}
					hideSeparator="true"
					headerRight={
						<div className="mt-2 ml-auto">
							<span className="flex flex-row items-end text-xl space-x-4 ml-2 lg:ml-0">
								<span className="flex flex-col items-end text-xl mb-1">
									<span className="text-sm">
										Total amounts
									</span>
									{price.amount !== 0 ? (
										<Price price={price} />
									) : (
										<div className="">0.00</div>
									)}
								</span>
								<span className="flex flex-col items-end text-xl mb-1">
									<span className="text-sm text-green-700">
										Reimbursable amount
									</span>
									{report.total_reimbursable.amount !== 0 ? (
										<>
											<div className="text-green-700">
												<Price price={report.total_reimbursable} />
											</div>
										</>
									) : (
										<>
											<div className="text-green-700">0.00</div>
										</>
									)}
								</span>
							</span>
						</div >
					}
				></Card >
				<Card header=" " headerRight=" ">
					<div className="flex flex-col lg:flex-row justify-between items-center">
						<div className="mb-4 lg:mb-0 lg:flex lg:items-center lg:space-x-4">
							<FormattedDate date={report.created_at} showIcon={true} />
							<div className="flex flex-col gap-3 w-72">
								{report.creator.id === user.id && report.status === "open" ? (
									<>
										<div>
											<ReportName report={report} />
										</div>
										<div>
											<UsersDropdown
												onChange={(selectedUserId) =>
													setToUser(parseInt(selectedUserId))
												}
												report={report}
												label="Change assigned administrator"
											/>
										</div>
									</>
								) : (
									<>
										<div>
											<span className="block uppercase text-xs text-left font-bold">
												Created by:
											</span>
											<span>
												{report.creator.first_name} {report.creator.last_name}{" "}
												({report.creator.email})
											</span>
										</div>
										<div>
											<span className="block uppercase text-xs text-left font-bold">
												Assigned to:
											</span>
											<span>
												{report.assignee.first_name} {report.assignee.last_name}{" "}
												({report.assignee.email})
											</span>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="flex flex-col lg:flex-row gap-2">
							{report.creator.id === user.id && report.status === "open" && (
								<Button
									width="sm:w-72 md:w-auto"
									label="Add expenses"
									onClick={handleOpenAddExpense}
								>
									<FontAwesomeIcon icon={faPlus} />
								</Button>
							)}

							{report.status === "processing" &&
								report.assignee.id === user.id &&
								isAdmin && (
									<>
										<Button
											width="w-72 lg:w-auto"
											label=" Mark as Reimbursed"
											onClick={handleReimburseReport}
										></Button>
										<Button
											width="w-72 lg:w-auto"
											label="Mark as Closed"
											onClick={handleCloseReport}
										></Button>
									</>
								)}

							<div className="hidden md:block">
								<DropDown width="w-64 lg:w-auto" label="Export">
									<div>
										<span
											className="block px-4 py-2 hover:bg-primary-700 cursor-pointer hover:text-white"
											onClick={handleExportToCsv}
										>
											<div className="flex flex-row items-center gap-2 text-sm">
												<FontAwesomeIcon icon={faFile} />
												Export to csv
											</div>
										</span>
									</div>
								</DropDown>
							</div>
						</div>
					</div>
					<div className="w-full border-b border-gray-200 pb-3"></div>
					<div className="block uppercase pt-4 text-center text-xl font-bold">
						Report expenses
					</div>
					<ReportExpenseByCategoryList
						categoryArray={categoryArray}
						report_id={report.id}
						handleOpenExpenseModal={handleOpenExpenseModal}
					/>
					<CommentSection report={report} />
				</Card>
				{
					modal.isOpen && modal.key === "editExpense" && (
						<Modal
							modalSubmit={modalSubmit}
							modalDelete={modalDelete}
							hiddenFooter={
								(exp.state !== "open" && exp.state !== "unreported") || (exp.user_id && user.id !== exp.user_id)
							}
							type="expenseForm"
						>
							{" "}
							<ExpenseForm />
						</Modal>
					)
				}
				{
					modal.isOpen && modal.key === "addExpense" && (
						<Modal modalSubmit={handleAddExpenseSubmit}>
							<ReportAddExpenseList
								reportNewExpenses={reportNewExpenses}
								setReportNewExpenses={setReportNewExpenses}
							/>
						</Modal>
					)
				}
			</Container >
			{showErrorAlert && (
				<Alert message={errorAlertMessage} type="error" duration={2000} />
			)}
		</>
	);
}
