import React, { useState, useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { format, subDays } from "date-fns";
import useShowChart from "../../actions/hooks/utility/useShowChart";

const ReportsGraph = ({ reports, graphType }) => {
	const chartRef = useRef(null);
	const today = new Date();
	const thirtyDaysAgo = subDays(today, 30);
	const { setChartData, setChart } = useShowChart();

	const [range, setRange] = useState({
		start: thirtyDaysAgo,
		end: today,
	});

	const filterReportsByRange = () => {
		// Create a new date for end with the time set to the end of the day
		const endDate = new Date(range.end);
		endDate.setHours(23, 59, 59, 999);

		return reports.filter((report) => {
			const reportDate = new Date(report.created_at);
			return (
				reportDate >= range.start &&
				reportDate <= endDate
			);
		});
	};

	useEffect(() => {
		const filteredReports = filterReportsByRange();
		if (filteredReports.length > 0) {
			const dateCountsMap = new Map();
			filteredReports.forEach((report) => {
				const reportDate = new Date(report.created_at);
				const formattedDate = format(reportDate, "dd/MM/yyyy");
				const amount = graphType === "euros" ? report.total.amount : 1;

				// Use formatted date as the key to aggregate amounts for the same date
				dateCountsMap.set(formattedDate, (dateCountsMap.get(formattedDate) || 0) + amount);
			});

			// Sort the keys (dates) in ascending order
			const sortedLabels = Array.from(dateCountsMap.keys()).sort((a, b) => {
				const dateA = new Date(a.split('/').reverse().join('-'));
				const dateB = new Date(b.split('/').reverse().join('-'));
				return dateA - dateB;
			});

			const sortedValues = sortedLabels.map(label => dateCountsMap.get(label));

			setChartData({
				xAxisLabels: sortedLabels,
				tableData: sortedValues,
			});
		} else {
			setChartData({
				xAxisLabels: Array(1).fill("No Reports"),
				tableData: Array(1).fill(0),
			});
		}

		setChart(chartRef.current);
	}, [range, reports, graphType]);

	const handleDateRangeChange = (selectedDates) => {
		if (selectedDates.length === 2) {
			setRange({
				start: selectedDates[0],
				end: selectedDates[1],
			});
		}
	};

	return (
		<div className="flex flex-col p-4 rounded-lg border shadow-lg bg-white">
			<h2 className="font-semibold mb-2">Select Date Range</h2>
			<Flatpickr
				value={[range.start, range.end]}
				options={{
					mode: "range",
					dateFormat: "d/m/Y",
					defaultDate: [thirtyDaysAgo, today],
					allowInput: true,
				}}
				onChange={handleDateRangeChange}
				className="form-input rounded-lg p-2 mb-4"
			/>
			<canvas ref={chartRef} />
		</div>
	);
};

export default ReportsGraph;
